<template>
  <div class="furniture-game">
    <div id="backwall">
      <hr :class="{full: !botOverlay}" id="edge">
      <furniture-game-room ref="userRoom" element-id="user" side="left" visibility />
      <furniture-game-room ref="botRoom" element-id="bot" side="right" :visibility="!botOverlay"/>
    </div>
    <transition name="fade">
      <div id="botOverlay" v-if="botOverlay">
        <div id="furnitureSelectionContainer">
          <div id="itemSelector" class="selectors">
            <p>
              <span class="button" :class="{disabled: selector.item.min == selector.item.index}" @click="moveWheel('left')">&lt;</span>
                item <span id="itemIndex">{{ selectorItem }}</span>
              <span class="button" :class="{disabled: selector.item.max == selector.item.index}" @click="moveWheel('right')">&gt;</span>
            </p>
          </div>
          <div id="furnitureSelector" class="selectors">
            <p>
              <span class="button" :class="{disabled: selector.furniture.max == selector.furniture.index}" @click="moveWheel('up')">&lt;</span>
                furniture <span id="furnitureIndex">{{ selectorFurniture }}</span>
              <span class="button" :class="{disabled: selector.furniture.min == selector.furniture.index}" @click="moveWheel('down')">&gt;</span>
            </p>
          </div>
          <div id="furnitureSelection" class="button" @click="selectFurniture()">
            <div class="wheel" :data-items="furniture.items" v-for="furniture, keyFurniture in furnitures" :key="keyFurniture">
              <img
                :src="require(`@/assets/${keyFurniture}-sprite-1x.png`)"
                :srcset="require(`@/assets/${keyFurniture}-sprite-1x.png`) + ' 2000w, ' +
                  require(`@/assets/${keyFurniture}-sprite-2x.png`) + ' 3840w'" />
            </div>
          </div>
        </div>
        <svg v-if="!instruction" class="counter flatShadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 322.83 302.28">
          <path d="M260.78,49.23c-55.54-48.69-156.39-66-217-3-60.78,63.18-43.48,168,12,217,77.57,68.46,226.26,24.5,256-63C330.11,146.29,300.46,84,260.78,49.23Z"/>
          <text text-anchor="middle" x="155" y="180">{{ countDown }}</text>
        </svg>
      </div>
    </transition>

    <div v-if="instruction" id="infoContainer">
      <p>Welcome to the style match-making game!</p>
      <p>You will have <b>{{ time }} seconds</b> to furnish your half of the room before the big reveal!</p>
      <!-- <p><span>{{ match }}</span>%</p> -->
      <p>Will there be a match?</p>
      <p>
        <router-link to="/clearing">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.72 26.28">
            <polygon fill="#231f20" points="45.72 9.97 22.79 9.97 22.79 0 0 13.14 22.79 26.28 22.79 16.31 45.72 16.31 45.72 9.97"/>
          </svg>
          Back to the jungle
        </router-link>
      </p>
      <svg @click="startGame(time)" class="start button flatShadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491.55 302.28">
        <path d="M398.9,49.23c-85.59-48.69-241-66-334.41-3-93.66,63.18-67,168,18.49,217,119.55,68.46,348.68,24.5,394.52-63C505.75,146.29,460,84,398.9,49.23Z"/>
        <text text-anchor="middle" x="250" y="180">Start!</text>
      </svg>
    </div>
    <div v-if="congrats" id="infoContainer" class="congrats">
      <p>Congratulations!</p>
      <p>If you liked the other half you may have found your match! Good luck!</p>
      <p>
        <router-link to="/clearing">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.72 26.28">
            <polygon fill="#231f20" points="45.72 9.97 22.79 9.97 22.79 0 0 13.14 22.79 26.28 22.79 16.31 45.72 16.31 45.72 9.97"/>
          </svg>
          Back to the jungle
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import FurnitureGameRoom from '@/components/FurnitureGameRoom.vue'
export default {
  components: { FurnitureGameRoom },
  name: 'FurnishGame',
  data () {
    return {
      countDown: 0,
      time: 120,
      match: 0,
      instruction: true,
      congrats: false,
      furnitures: null,
      botOverlay: true,
      selector: {
        furniture: {
          index: 0,
          min: 0,
          max: 1
        },
        item: {
          index: 0,
          min: 0,
          max: 1
        }
      }
    }
  },
  methods: {
    leftPad (value) {
      return ('00' + value).slice(-2)
    },
    moveWheel (direction) {
      const fur = this.selector.furniture
      const itm = this.selector.item
      const wheelItems = document.querySelectorAll('.wheel')

      if ((direction === 'up' && fur.max > fur.index) ||
          (direction === 'down' && fur.min < fur.index)) {
        direction === 'up' ? fur.index++ : fur.index--
        itm.index = 0
        itm.max = wheelItems[fur.index].dataset.items - 1
        const offset = `-${fur.index * wheelItems[0].offsetHeight}px`
        wheelItems.forEach(item => {
          item.style.top = offset
          item.childNodes[0].style.left = 0
        })
      } else if ((direction === 'left' && itm.min < itm.index) ||
          (direction === 'right' && itm.max > itm.index)) {
        direction === 'left' ? itm.index-- : itm.index++
        const wheel = fur.index
        const furniture = wheelItems[wheel]
        const offset = `-${Math.round(itm.index * furniture.childNodes[0].offsetWidth / furniture.dataset.items)}px`
        furniture.childNodes[0].style.left = offset
      }
    },
    selectFurniture () {
      this.$refs.userRoom.changeFurniture(this.selector)
    },
    checkConsonance () {
      const obj1 = this.$refs.botRoom.getFurnitureIndex()
      const obj2 = this.$refs.userRoom.getFurnitureIndex()
      const total = Object.keys(obj1).length
      let concordant = 0

      for (const [key, value] of Object.entries(obj1)) {
        if (value === obj2[key]) {
          concordant++
        }
      }
      this.match = Math.round(concordant / total * 100)
    },
    startGame (seconds) {
      this.instruction = false
      this.$store.dispatch('hideHelp')
      this.botOverlay = true
      this.setCountDown(seconds)
    },
    setCountDown (seconds) {
      this.countDown = seconds
    },
    showResult () {
      this.$refs.userRoom.chooseRandomFurnitures('bot')
      // this.checkConsonance()
      this.botOverlay = false
      // console.log(this.match)
      this.$store.dispatch('showHelp')
      setTimeout(() => { this.congrats = true }, 7000)
    },
    handleKey (e) {
      // console.log(e)
      switch (e.key) {
        case 'ArrowLeft':
        case 'a':
          this.moveWheel('left')
          break

        case 'ArrowRight':
        case 'd':
          this.moveWheel('right')
          break

        case 'ArrowUp':
        case 'w':
          this.moveWheel('down')
          break

        case 'ArrowDown':
        case 's':
          this.moveWheel('up')
          break

        case 'Enter':
        case ' ':
          this.selectFurniture()
          break
        default:
          break
      }
    }
  },
  computed: {
    selectorFurniture () {
      return this.leftPad(this.selector.furniture.index + 1)
    },
    selectorItem () {
      return this.leftPad(this.selector.item.index + 1)
    }
  },
  created () {
    this.furnitures = this.$store.getters.furnitures
    this.selector.furniture.max = Object.keys(this.furnitures).length - 1
    this.selector.item.max = this.furnitures[Object.keys(this.furnitures)[0]].items - 1

    window.addEventListener('keydown', this.handleKey)
  },
  watch: {
    countDown: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.countDown--
          }, 1000)
        } else if (value === 0) {
          this.countDown--
          this.showResult()
        }
      }
    }
  },
  mounted () {
    this.$refs.userRoom.chooseRandomFurnitures('bot')
    this.$refs.botRoom.chooseRandomFurnitures('user')
    // console.log(this.$refs.botRoom.getFurnitureIndex())
    this.checkConsonance()
  },
  beforeUnmount () {
    this.$store.dispatch('showHelp')
    window.removeEventListener('keydown', this.handleKey)
  }
}
</script>

<style scoped>
  .furniture-game {
    width: 100%;
    height: 100%;
    background-color: aquamarine;
    background-image: -webkit-image-set(url('~@/assets/furniture-bg-1x.png') 1.25x, url('~@/assets/furniture-bg-2x.png') 2x);
    background-size: cover;
  }

  #edge {
    margin: 0;
    padding: 0;
    height: 0.4vh;
    width: 50vw;
    position: absolute;
    top: 56vh;
    background-color: #000;
    border: none;
    outline: none;
    transition: width 1s ease-in-out;
  }

  #edge.full {
    width: 100vw;
    transition: width 1s ease-in-out;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  #botOverlay {
    position: absolute;
    height: 100%;
    width: 50%;
    background-color: transparent;
    top: 0;
    right: 0;
    z-index: 20;
    border-left: 0.4vw solid #000;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  #furnitureSelectionContainer {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
  }

  #furnitureSelectionContainer > *:first-child {
    grid-column-start: 2;
  }

  .selectors {
    z-index: 1;
    user-select: none;
  }

  .selectors > p {
    font-size: 2.5vh;
    margin: 0.5em;
  }

  #furnitureSelector > p {
    align-self: center;
    writing-mode: vertical-lr;
    transform: rotateZ(180deg);
  }

  .disabled {
    opacity: 0;
    cursor: inherit;
  }

  #furnitureSelector {
    display: grid;
    width: min-content;
  }

  #furnitureSelection {
    background-color: transparent;
    width: 31vw;
    height: 65vh;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 10000px #deffee;
    border: 0.4vh solid #000;
  }

  .wheel {
    position: relative;
    top: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    transition: top 0.7s ease-in-out 0s;
  }

  .wheel img {
    position: absolute;
    height: 100%;
    left: 0;
    transition: left 0.7s ease-in-out 0s;
  }

  svg.counter {
    z-index: 120;
    position: absolute;
    right: 3vw;
    top: 5vh;
    height: 8vh;
  }

  svg.counter path {
    fill: #fff;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 11px;
  }

  svg.counter text {
    font-size: 110px;
  }

  #infoContainer {
    background-color: rgb(255 255 255 / 100%);
    max-width: 900px;
    padding: 1.5vh 3vw;
    border: 0.4vh solid #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    font-size: 3vh;
    text-align: left;
  }

  #infoContainer.congrats {
    text-align: center;
  }

  #infoContainer.congrats p:last-child {
    margin-top: 7vh;
  }

  #infoContainer.congrats a {
    font-size: 4vh;
    font-weight: 700;
  }

  #infoContainer p svg {
    display: inline-block;
    height: 0.5em;
    margin: 0vh 0.25vw 0vh 0vw;
    padding-bottom: 0.25vh;
  }

  #infoContainer a {
    color: #000;
    text-decoration: none;
    font-size: 2vh;
  }

  svg.start {
    height: 14vh;
    position: absolute;
    bottom: 4vh;
    right: 4vw;
  }

  svg.start path {
    fill: #a6ffd4;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 11px;
  }

  svg.start text {
    font-size: 115px;
    font-weight: 700;
  }

</style>
