<template>
  <div class="furniture" :style="parseStyle(furniture.style)" :class="[keyFurniture, elementId, {hidden: !visibility}]" :data-items="furniture.items" v-for="furniture, keyFurniture in furnitures" :key="keyFurniture">
    <img :src="require(`@/assets/furniture/${keyFurniture}/${index+1}.png`)" v-for="furniture, index in furniture.items" :key="index"/>
  </div>
</template>

<script>
export default {
  name: 'FurnitureGameRoom',
  props: {
    elementId: {
      type: String,
      required: true
    },
    side: {
      type: String,
      required: true
    },
    visibility: {
      type: Boolean
    }
  },
  data () {
    return {
      furnitures: null
    }
  },
  created () {
    this.furnitures = this.$store.getters.furnitures
  },
  methods: {
    parseStyle (styleObject) {
      let style = ''
      for (let [key, value] of Object.entries(styleObject)) {
        key = key === 'outside' ? this.side : key
        style += `${key}: ${value};`
      }
      return style
    },
    chooseRandomFurnitures (querySelector) {
      for (const [furniture, value] of Object.entries(this.furnitures)) {
        const item = (Math.random() * (value.items - 1) + 1) << 0
        const items = document.querySelector(`.${querySelector}.furniture.${furniture}`).childNodes
        items.forEach(item => {
          item.className = ''
        })
        items[item].className = 'active'
      }
    },
    getFurnitureIndex () {
      const furnitureObject = {}
      for (const furniture of Object.keys(this.furnitures)) {
        const elem = document.querySelector(`.${this.elementId}.furniture.${furniture} .active`)
        const index = Array.from(elem.parentNode.children).indexOf(elem)
        furnitureObject[furniture] = index
      }
      return furnitureObject
    },
    changeFurniture (selector) {
      const furniture = document.querySelectorAll('.furniture.user')[selector.furniture.index]
      furniture.children.forEach((item, index) => {
        if (index === selector.item.index) {
          item.className = 'active'
        } else {
          item.className = ''
        }
      })
    }
  }
}
</script>

<style scoped>
  .furniture {
    position: absolute;
    pointer-events: none;
    transition: opacity 1s linear 1s;
  }

  .furniture > img {
    display: block;
    opacity: 0;
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    transition: opacity 0.5s ease-in;
    position: absolute;
  }

  .bot.furniture > img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    /* opacity: 0 !important; TODO: remove this */
  }

  .furniture > img.active {
    opacity: 1;
    transition: all 1s ease-in-out 0.5s;
    /* transition-property: top, left; */
  }
</style>
